/* Landing Page Styles */
.landing-page {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.header-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #1a237e, #3949ab, #5c6bc0, #FF6B6B, #FF8E53);
  z-index: 1;
}

.header-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent);
}

.logo-container {
  position: relative;
  padding: 5px;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.logo-container:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.nav-links {
  display: flex;
  align-items: center;
}

/* Hero Section */
.hero-title {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
}

.hero-subtitle {
  opacity: 0.9;
  max-width: 600px;
  margin-bottom: 1rem;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.hero-description {
  opacity: 0.8;
  max-width: 600px;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.6;
}

.highlight {
  color: #FFA726;
  position: relative;
  display: inline-block;
  font-weight: 800;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-color: rgba(255, 167, 38, 0.3);
  z-index: -1;
  border-radius: 4px;
}

.hero-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

/* Financial Illustration */
.finance-illustration {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finance-circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.05) 100%);
  border: 2px solid rgba(255,255,255,0.3);
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  animation: pulse 4s infinite ease-in-out;
}

.finance-chart {
  position: absolute;
  width: 200px;
  height: 100px;
  background: linear-gradient(to top right, transparent 49.5%, #FFA726 49.5%, #FFA726 50.5%, transparent 50.5%);
  transform: rotate(-10deg);
  animation: chart 3s infinite ease-in-out;
}

.finance-dollar {
  position: absolute;
  font-size: 100px;
  font-weight: bold;
  color: rgba(255,255,255,0.9);
  text-shadow: 0 5px 15px rgba(0,0,0,0.2);
  animation: float 3s infinite ease-in-out;
}

.finance-coins {
  position: absolute;
  width: 100%;
  height: 100%;
}

.coin {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(135deg, #FFD54F 0%, #FFC107 100%);
  border: 2px solid rgba(255,255,255,0.5);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.coin-1 {
  top: 30%;
  left: 20%;
  animation: float 4s infinite ease-in-out;
}

.coin-2 {
  bottom: 30%;
  right: 25%;
  animation: float 3.5s infinite ease-in-out 0.5s;
}

.coin-3 {
  top: 60%;
  left: 30%;
  animation: float 5s infinite ease-in-out 1s;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

@keyframes chart {
  0%, 100% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}

/* Wave shape */
.wave-shape {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 50px;
  background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" fill="%23FFFFFF"/><path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" fill="%23FFFFFF"/><path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" fill="%23FFFFFF"/></svg>') no-repeat;
  background-size: cover;
}

/* How It Works Section */
.process-steps {
  position: relative;
}

.process-step {
  display: flex;
  margin-bottom: 30px;
  position: relative;
}

.step-number {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(135deg, #1a237e 0%, #3949ab 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  flex-shrink: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.step-content {
  margin-left: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.process-step:hover .step-content {
  transform: translateX(5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.process-step::after {
  content: '';
  position: absolute;
  left: 25px;
  top: 50px;
  bottom: -30px;
  width: 2px;
  background: linear-gradient(to bottom, #1a237e, rgba(26, 35, 126, 0.2));
  z-index: 1;
}

.process-step:last-child::after {
  display: none;
}

.flow-diagram {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  position: relative;
}

.flow-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 10px;
}

.investor-circle {
  background: linear-gradient(135deg, #1a237e 0%, #3949ab 100%);
  color: white;
}

.platform-circle {
  background: linear-gradient(45deg, #FF6B6B 0%, #FF8E53 100%);
  color: white;
  transform: scale(1.2);
}

.borrower-circle {
  background: linear-gradient(135deg, #1a237e 0%, #3949ab 100%);
  color: white;
}

.flow-arrow {
  height: 2px;
  flex-grow: 1;
  background: linear-gradient(to right, #1a237e, #FF8E53);
  position: relative;
  margin: 0 10px;
}

.flow-arrow::after {
  content: '';
  position: absolute;
  right: -5px;
  top: -4px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #FF8E53;
}

/* Feature Items */
.feature-item {
  padding: 30px;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

/* Market Statistics Chart */
.market-chart {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
}

.chart-bars {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.chart-bar {
  width: 60px;
  border-radius: 6px 6px 0 0;
  margin: 0 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.chart-bar:hover {
  transform: scaleY(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Investment Calculator */
.calculator-form {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.result-card {
  background: linear-gradient(135deg, #1a237e 0%, #3949ab 100%);
  color: white;
  padding: 30px;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* User Testimonials */
.user-testimonial {
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  text-align: center;
}

.user-testimonial::before {
  content: '"';
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 60px;
  color: rgba(26, 35, 126, 0.1);
  font-family: Georgia, serif;
  line-height: 1;
}

.user-testimonial:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

/* Testimonial Cards */
.MuiCardMedia-root {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.MuiCardContent-root {
  text-align: center;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing-page section {
  animation: fadeIn 0.8s ease-out forwards;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #7390ef;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5c6bc0;
}
